import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SegmentRenderer from "../components/segmentRenderer"

export const query = graphql`
query HomePageQuery {
  page: sanityPageWithContent(_id: {eq: "homePage"}) {
    seo {
      title
      image {
        asset {
          url
        }
      }
      description
    }
    _rawContent(resolveReferences: {maxDepth: 10})
  }
}
`

const Home = props => {
  const { page } = props.data
  const seo = page?.seo
  return (
    <Layout title={seo.title} description={seo.description} image={seo.image}>
      <div className="text-container pb-100">
        <SegmentRenderer content={page._rawContent} />
      </div>
    </Layout>
  )
}

export default Home
